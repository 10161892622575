@import '~antd/dist/antd.less';

body {
  font-size: 14px;
  font-family: "Lato", helveticaneue, helvetica neue, helvetica-neue, Helvetica;
}

p {
  font-size: 130%;
  line-height: 114%;
  font-family: "Lato", helveticaneue, helvetica neue, helvetica-neue, Helvetica,
  Arial, sans-serif;
}

h1 {
  font-size: 300%;
  line-height: 46px;
}

h1.establishmentName {
  font-family: "Caveat", helveticaneue, helvetica neue, helvetica-neue,
  Helvetica, Arial, sans-serif;
  font-size: 2rem;
  color: #fff;
  margin: 0;
  white-space: nowrap;
}

h2 {
  font-size: 200%;
  line-height: 126%;
}

h3 {
  font-size: 190%;
}

h4 {
  font-size: 180%;
}

h5 {
  font-size: 150%;
}

h6 {
  font-size: 120%;
}

html {
  font-size: 110%;
}

.gallery__image--detail {
  max-width: 80%;
}

.gallery__list {
  width: 10em;
  height: 10em;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .gallery__list {
    width: 5em;
    height: 5em;
  }

  .gallery__horizontal {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }

  .section__gallery__horizontal {
    overflow: hidden;
  }
}

.gallery__list--link {
  margin-right: 1em;
  margin-bottom: 1em;
}

.horary__label:not(:last-child) {
  margin-right: 1em;
}

@media (min-width: 60em) {
  html {
    font-size: 90%;
  }
}

html {
  font-size: 100%;
}

@media (min-width: 60em) {
  html {
    font-size: 100%;
  }
}

.container {
  margin: auto;
}

@media (min-width: 568px) {
  .container {
    width: 550px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media only screen and (max-width: 767px) {
  .heading__footer {
    height: 50% !important;
  }

  .heading__form {
    flex-direction: column;
  }

  .heading__form__select,
  .heading__form__btn,
  .heading__form__link button,
  .heading__form__link {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.grid-restaurants {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "PT Sans", helveticaneue, helvetica neue, helvetica-neue,
  Helvetica, Arial, sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.home-overlay {
  background-image: linear-gradient(rgba(00, 00, 00, .95), hsla(0, 50%, 100%, 0));
}

@media only screen and (max-width: 767px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .grid-restaurants {
    grid-template-columns: 1fr 1fr;
  }

  .grid-restaurants h3 {
    font-size: 18px;
  }

  .card-follow-restaurant {
    display: none;
  }

  .hideOnMobile {
    display: none !important;
  }

  .home-overlay {
    background-image: linear-gradient(rgba(00, 00, 00, .95), hsla(0, 50%, 100%, 0));
  }

  .qm-container.grid .col1 {
    order: 2;
  }

  .home-slider {
    height: 300px !important;
  }

  .container-grid-restaurants {
    margin: 1em !important;
  }

}

@media only screen and (min-width: 768px) {

  .hideOnDesktop {
    display: none !important;
  }

}

.item__restaurant {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
}

.qm-container {
  max-width: 1200px;
  margin: auto;
}

.font-caveat {
  font-family: "Caveat", helveticaneue, helvetica neue, helvetica-neue, Helvetica, Arial, sans-serif;
}

.SRLControls {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 1337;
}
#SRLLightbox{
  z-index: 1333;
}
.btn_order{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #E01C26;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  height: 60px;
}
@media only screen and (min-width: 768px) {
  .image-corousel{
    border-radius: 5px;
  }
}
.image-corousel{
  height: 350px;
  object-fit: cover;
}
.schedulePanel,
.scheduleCollapse{
  background: transparent!important;
  border: 0!important;

  .ant-collapse-content-box,
  .ant-collapse-content,
  .ant-collapse-header{
    padding: 0!important;
    border: 0!important;
  }
  .ant-collapse-content-box{
    margin-top: 20px;
  }

  svg{
    font-size: 18px!important;
    position: relative;
    left: 12px;
  }

}

.restaurant-header{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (min-width: 768px) {
  .restaurant-grid{
    margin-top: 10px!important;
  }

}

.ant-layout{
  overflow: hidden!important;
}
@primary-color: #E01C26;@btn-font-weight: 700;